export const teamData = {
	a: [
		{
			name: "Chaitanya",
			designation: "Founder",
			img: require("../assets/chaitanya.png"),
			insta: "https://instagram.com/chaitanya1609?igshid=YmMyMTA2M2Y",
			linkedin: "https://www.linkedin.com/in/chaitanya-chhabra",
		},
		{
			name: "Piyush Bathla",
			designation: "Chief-Financial Officer",
			img: require("../assets/piyush.png"),
			insta: "https://www.instagram.com/piyushbathla._/",
			linkedin: "https://www.linkedin.com/in/piyush-bathla-387a401ab/",
		},
		// {
		// 	name: "Vyom Chhabra",
		// 	designation: "Digital Performance Specialist",
		// 	img: require("../assets/vyom.png"),
		// 	insta: "https://instagram.com/dude.im_baked?igshid=YmMyMTA2M2Y",
		// 	linkedin: "https://www.linkedin.com/in/vyom-chhabra-9b93791a4",
		// },
	],
	b: [
		// {
		//     name: 'Vishu',
		//     designation: "Social Media Specialist",
		//     img: require('../assets/vishu.png'),
		//     insta: 'https://instagram.com/vishuchauhan05?igshid=YmMyMTA2M2Y',
		//     linkedin: 'https://www.linkedin.com/in/vishu-chauhan-a5a3301a8'
		// },
		// {
		//     name: 'Palak',
		//     designation: 'Campaigns and Talent Manager',
		//     img: require('../assets/palak.png'),
		//     insta: 'https://instagram.com/_.palakkk?igshid=YmMyMTA2M2Y',
		//     linkedin: 'https://www.linkedin.com/in/palakmihani'
		// }
	],
};
